import React from "react";
import styled from "styled-components";

const ContactForm = () => (
  <Form
    name="contact"
    method="post"
    data-netlify="true"
    data-netlify-honeypot="bot-field"
  >
    <input type="hidden" name="form-name" value="contact" />
    <Label>
      Name
      <Input type="text" name="name" id="name" />
    </Label>
    <Label>
      Email
      <Input type="email" name="email" id="email" />
    </Label>
    <Label>
      Message
      <TextArea name="message" id="message" rows="10" />
    </Label>
    <Button type="submit" primary>
      Send
    </Button>
    <Reset type="reset" value="Clear" />
  </Form>
);

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  font-size: 1em;
  justify-content: center;
`;

const Input = styled.input`
  padding: 0.5em;
  margin-top: 0.5em;
  border: 1px solid;
  border-radius: 3px;
  font-size: 1em;
`;

const TextArea = styled.textarea`
  padding: 0.5em;
  margin-top: 0.5em;
  border: 1px solid;
  border-radius: 3px;
  font-size: 1em;
`;

const Button = styled.button`
  align-self: center;
  background: #337ab7;
  color: white;
  width: 100px;
  font-size: 1em;
  margin-top: 1em;
  padding: 0.25em 1em;
  border: 2px solid #337ab7;
  border-radius: 3px;
`;

const Reset = styled.input`
  align-self: center;
  background: grey;
  color: white;
  width: 100px;
  font-size: 1em;
  margin: 1em 0;
  padding: 0.25em 1em;
  border: 2px solid grey;
  border-radius: 3px;
`;

const Label = styled.label`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding-top: 1em;
  text-align: left;
`;

export default ContactForm;
