import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../containers";
import {
  ResponsiveTitle1,
  ResponsiveTitle2,
  colour,
} from "../components/styled";
import {
  PortableText,
  Container,
  SEO,
  GraphQLError,
} from "../components/elements";
import { buildImageObj, imageUrlFor } from "../helpers";
import useFirebaseAnalytics from "../hooks/useFirebaseAnalytics";
import ContactForm from "../components/Forms/ContactForm";

export const query = graphql`
  query AboutPageQuery {
    page: sanityAbout(_id: { regex: "/(drafts.|)about/" }) {
      _rawBio(resolveReferences: { maxDepth: 5 })
      image {
        alt
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
        }
      }
      name
    }
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      email
    }
    policy: sanityReviewPolicy(_id: { regex: "/(drafts.|)reviewPolicy/" }) {
      title
      _rawBody
    }
  }
`;

const AboutPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLError errors={errors} />
      </Layout>
    );
  }
  const { _rawBio: body, image } = data && data.page;
  const contactInfo = data.site;
  const { _rawBody: policy, title: policyTitle } = data && data.policy;

  const title = "About";

  useFirebaseAnalytics(`PAGE: ${title}`);
  return (
    <Layout>
      <SEO title={title} />
      <Container>
        <ResponsiveTitle1>{title}</ResponsiveTitle1>

        <Avatar>
          {image && image.asset && (
            <img
              src={imageUrlFor(buildImageObj(image))
                .width(100)
                .height(100)
                .fit("crop")
                .url()}
              loading="lazy"
              alt={image.alt}
            />
          )}
        </Avatar>
        {body && <PortableText blocks={body} />}
        <Divider />
        <Content>
          <ResponsiveTitle2>Contact Details</ResponsiveTitle2>
          <ContactForm />
          <Divider />

          <ResponsiveTitle2>{policyTitle}</ResponsiveTitle2>

          <PortableText blocks={policy} />
          <div>
            <span>Email: </span>
            <a href={`mailto:${contactInfo.email}`}>{contactInfo.email}</a>
          </div>
          <Spacer />
        </Content>
      </Container>
    </Layout>
  );
};

const Avatar = styled.div`
  margin-right: auto;
  width: 3em;
  height: 3em;
  background: ${colour.veryLightGray};
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    vertical-align: top;
    object-fit: cover;
  }
`;

const Spacer = styled.div`
  margin: 0 3rem;
  padding: 0 3rem;
`;

const Content = styled.div`
  text-align: center;
`;

const Divider = styled.hr`
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
`;

export default AboutPage;
